import React, { Suspense, lazy, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import NotificationProvider from "use-toast-notification";
import Loading from "components/loading/Loading";
import "./App.css";
import { GG_WEB_CLIENT_ID } from "constants/Constants";
import { GoogleOAuthProvider } from "@react-oauth/google";
import AppFooter from "components/appFooter/AppFooter";

const Home = lazy(() => import("./pages/home/Home"));
const Intro = lazy(() => import("./pages/intro/Intro"));
const Course = lazy(() => import("./pages/course/Course"));
const Topic = lazy(() => import("./pages/topic/Topic"));
const Lesson = lazy(() => import("./pages/lesson/Lesson"));
const Account = lazy(() => import("./pages/account/Account"));
const LessonDetail = lazy(() => import("./pages/lessonDetail/LessonDetail"));
const Event = lazy(() => import("./pages/event/Event"));
const EventDetail = lazy(() => import("./pages/eventDetail/Event"));
const Login = lazy(() => import("./pages/login/Login"));
const Register = lazy(() => import("./pages/register/Register"));
const ResultExam = lazy(() => import("./pages/resultExam"));
const Exam = lazy(() => import("./pages/exam/Exam"));
const CoursePayment = lazy(() => import("./pages/coursePayment/CoursePayment"));
const ListFreeCourse = lazy(() =>
  import("./pages/listFreeCourse/ListFreeCourse")
);
const FreeCourse = lazy(() => import("./pages/freeCourse/FreeCourse"));
const Book = lazy(() => import("./pages/book/Book"));
const BookDetail = lazy(() => import("./pages/bookDetail/BookDetail"));
const BookPlayer = lazy(() => import("./pages/bookPlayer/BookPlayer"));
const ListFreeBook = lazy(() => import("./pages/listFreeBook/ListFreeBook"));

const App = () => {
  const userInfoString = localStorage.getItem("TOKEN");
  const [isAuthen, setIsAuthen] = useState(false);
  useEffect(() => {
    setIsAuthen(!!userInfoString);
  }, [userInfoString]);
  return (
    <div className="App">
      <GoogleOAuthProvider clientId={GG_WEB_CLIENT_ID}>
        <NotificationProvider
          config={{
            position: "top-right",
            isCloseable: false,
            showTitle: true,
            showIcon: true,
            duration: 300,
          }}
          overrideStyles={{
            border: "2px solid red",
          }}
        >
          <Router>
            {!isAuthen ? (
              <Suspense fallback={<Loading />}>
                <div style={{ paddingBottom: "97px" }}>
                  <Switch>
                    <Route path="/" exact component={() => <Home />} />
                    <Route path="/login" exact component={() => <Login />} />
                    <Route
                      path="/register"
                      exact
                      component={() => <Register />}
                    />
                    <Route path="/home" exact component={() => <Home />} />
                    <Route
                      path="/account"
                      exact
                      component={() => <Account />}
                    />
                    <Route path="/course" exact component={() => <Course />} />
                    <Route
                      path="/course/:courseId"
                      exact
                      component={() => <Topic />}
                    />
                    <Route
                      path="/course/:courseId/lesson/:lessonId"
                      exact
                      component={() => <Lesson />}
                    />
                    <Route
                      path="/course/:courseId/lesson/:lessonId/detail/:videoId"
                      exact
                      component={() => <LessonDetail />}
                    />
                    <Route path="/event" exact component={() => <Event />} />
                    <Route
                      path="/event/:eventId"
                      exact
                      component={() => <EventDetail />}
                    />
                    <Route
                      path="/course/:courseId/exam"
                      exact
                      component={() => <Exam />}
                    />
                    <Route
                      path="/course-payment"
                      exact
                      component={() => <CoursePayment />}
                    />
                    <Route
                      path="/free-course"
                      exact
                      component={() => <ListFreeCourse />}
                    />
                    <Route
                      path="/free-course/:courseId"
                      exact
                      component={() => <FreeCourse />}
                    />
                    <Route path="/book" exact component={() => <Book />} />
                    <Route
                      path="/book/:bookId"
                      exact
                      component={() => <BookDetail />}
                    />
                    <Route
                      path="/book/:bookId/player"
                      exact
                      component={() => <BookPlayer />}
                    />
                    <Route
                      path="/free-book"
                      exact
                      component={() => <ListFreeBook />}
                    />
                  </Switch>
                </div>
              </Suspense>
            ) : (
              <Suspense fallback={<Loading />}>
                {/* <AppHeader /> */}
                <div style={{ paddingBottom: "97px" }}>
                  <Switch>
                    <Route path="/login" exact component={() => <Login />} />
                    <Route
                      path="/register"
                      exact
                      component={() => <Register />}
                    />
                    <Route path="/home" exact component={() => <Home />} />
                    <Route
                      path="/account"
                      exact
                      component={() => <Account />}
                    />
                    <Route
                      path="/course-payment"
                      exact
                      component={() => <CoursePayment />}
                    />
                    <Route path="/course" exact component={() => <Course />} />
                    <Route
                      path="/course/:courseId"
                      exact
                      component={() => <Topic />}
                    />
                    <Route
                      path="/course/:courseId/lesson/:lessonId"
                      exact
                      component={() => <Lesson />}
                    />
                    <Route
                      path="/course/:courseId/lesson/:lessonId/detail/:videoId"
                      exact
                      component={() => <LessonDetail />}
                    />
                    <Route path="/event" exact component={() => <Event />} />
                    <Route
                      path="/event/:eventId"
                      exact
                      component={() => <EventDetail />}
                    />
                    <Route
                      path="/course/:courseId/exam"
                      exact
                      component={() => <Exam />}
                    />
                    <Route
                      path="/free-course"
                      exact
                      component={() => <ListFreeCourse />}
                    />
                    <Route
                      path="/free-course/:courseId"
                      exact
                      component={() => <FreeCourse />}
                    />
                    <Route path="/book" exact component={() => <Book />} />
                    <Route
                      path="/book/:bookId"
                      exact
                      component={() => <BookDetail />}
                    />
                    <Route
                      path="/book/:bookId/player"
                      exact
                      component={() => <BookPlayer />}
                    />
                    <Route
                      path="/free-book"
                      exact
                      component={() => <ListFreeBook />}
                    />
                  </Switch>
                </div>
                {/* <AppFooter /> */}
              </Suspense>
            )}
          </Router>
        </NotificationProvider>
      </GoogleOAuthProvider>
    </div>
  );
};

export default App;

export const CLEAR_ERROR_MESSAGE = "CLEAR_ERROR_MESSAGE";

export const GET_LIST_TOPIC = "GET_LIST_TOPIC";
export const GET_LIST_TOPIC_SUCCESS = "GET_LIST_TOPIC_SUCCESS";
export const GET_LIST_TOPIC_FAIL = "GET_LIST_TOPIC_FAIL";

export const GET_LIST_COURSE_BY_TOPIC = "GET_LIST_COURSE_BY_TOPIC";
export const GET_LIST_COURSE_BY_TOPIC_SUCCESS = "GET_LIST_COURSE_BY_TOPIC_SUCCESS";
export const GET_LIST_COURSE_BY_TOPIC_FAIL = "GET_LIST_COURSE_BY_TOPIC_FAIL";

export const GET_LIST_LESSION_BY_COURSE = "GET_LIST_LESSION_BY_COURSE";
export const GET_LIST_LESSION_BY_COURSE_SUCCESS = "GET_LIST_LESSION_BY_COURSE_SUCCESS";
export const GET_LIST_LESSION_BY_COURSE_FAIL = "GET_LIST_LESSION_BY_COURSE_FAIL";

export const GET_COURSE_DETAIL = "GET_COURSE_DETAIL";
export const GET_COURSE_DETAIL_SUCCESS = "GET_COURSE_DETAIL_SUCCESS";
export const GET_COURSE_DETAIL_FAIL = "GET_COURSE_DETAIL_FAIL";
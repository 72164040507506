export const CLEAR_ERROR_MESSAGE = "CLEAR_ERROR_MESSAGE";

//courses
export const GET_COURSES = "GET_COURSES";
export const GET_COURSES_SUCCESS = "GET_COURSES_SUCCESS";
export const GET_COURSES_FAIL = "GET_COURSES_FAIL";

//cbooks
export const GET_BOOKS = "GET_BOOKS";
export const GET_BOOKS_SUCCESS = "GET_BOOKS_SUCCESS";
export const GET_BOOKS_FAIL = "GET_BOOKS_FAIL";
//cbooks
export const GET_MY_BOOKS = "GET_MY_BOOKS";
export const GET_MY_BOOKS_SUCCESS = "GET_MY_BOOKS_SUCCESS";
export const GET_MY_BOOKS_FAIL = "GET_MY_BOOKS_FAIL";
//questions
export const GET_QUESTIONS_ANSWER = "GET_QUESTIONS_ANSWER";
export const GET_QUESTIONS_ANSWER_SUCCESS = "GET_QUESTIONS_ANSWER_SUCCESS";
export const GET_QUESTIONS_ANSWER_FAIL = "GET_QUESTIONS_ANSWER_FAIL";